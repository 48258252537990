import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/tsuto/webapp/pcrecommend/src/components/layout.js";
import Image from "components/image";
import TOC from "components/toc";
import SaleLoadable from "components/saleLoadable";
import Socials from "components/socials";
import SmallBox from "components/smallBox";
import { Link, graphql } from "gatsby";
export const query = graphql`
  {
    allMdx(filter: {slug: {eq: "monitor/4k43inch"}}) {
      nodes {
        headings {
          depth
          value
        }
      }
    },
    cover: file(relativePath: { eq: "cover/fourk43inch-monitor.jpg"}) { ...eyecatchImg },
    onscreencontrol: file(relativePath: { eq: "monitor/on-screen-control.PNG"}) { ...normalImg },
    monitoredge: file(relativePath: { eq: "monitor/monitor-edge.png"}) { ...normalImg },
    lettersize: file(relativePath: { eq: "monitor/lettersize.PNG"}) { ...normalImg },
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`4K43インチモニタの使い勝手は？`}</h1>
    <Socials {...props} mdxType="Socials" />
    <Image {...props} name="cover" alt="4K43インチモニタ" mdxType="Image" />
    <p>{`23.8インチのFullHDモニタ２枚をこれまで使ってきた管理人であったが、プログラミング中や株取引でもう一枚パネルがあると生産効率が上がるのではと考え4K43インチ(`}<a parentName="p" {...{
        "href": "https://amzn.to/2JUzEMP",
        "target": "_blank",
        "rel": "nofollow noopener"
      }}>{`LG 43UD79-B`}</a>{`)を導入し１年間運用してみた。結論から言えば使いやすい時もあればデュアルモニタの方が良い場合もあり、どちらがおすすめということは一概に言うことはできない。ともあれ購入した時には気づかず、１年間の使用を通じて気がついたポイントをここでは述べていきたい。`}</p>
    <SaleLoadable title="現在のパソコンセール・お買い得情報" mdxType="SaleLoadable" /><br />
    <TOC {...props} mdxType="TOC" />
    <h2 {...{
      "id": "画面分割ソフトよりFullHDの４本出力",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E7%94%BB%E9%9D%A2%E5%88%86%E5%89%B2%E3%82%BD%E3%83%95%E3%83%88%E3%82%88%E3%82%8AFullHD%E3%81%AE%EF%BC%94%E6%9C%AC%E5%87%BA%E5%8A%9B",
        "aria-label": "画面分割ソフトよりFullHDの４本出力 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`画面分割ソフトよりFullHDの４本出力`}</h2>
    <p>{`43インチディスプレイでは１画面が大きすぎて画面をうまく分割してやらなければ作業効率は上がっていかない。`}</p>
    <p>{`たとえば、Webサイトを閲覧するのにはFullHDでも大きいぐらいであるが、それが最大化ボタンでディスプレイ全体に広がってしまう。全体に広がらないように、画面の端で拡大縮小を行うのも良いが、適度な大きさにするのはストレスのかかる作業である。`}</p>
    <p>{`これを解決するためにLGはOnScreen Controlという、画面サイズを簡単に調整してくれるソフトウェアを備えているのだが、これが実に惜しい。`}</p>
    <Image {...props} name="onscreencontrol" alt="LGのOnScreen Control" mdxType="Image" />
    <p>{`使い方は、どの画面分割（上図参考）でモニタを使うかをまず決定し、その後Windowをドラッグアンドドロップするとそのサイズにフィットするように拡大縮小を行ってくれる。`}</p>
    <p>{`確かに4K43インチのモニタをそのままで使うのならば必要となる機能なのだが、`}<strong parentName="p">{`常にその分割で使いたいわけではなく`}</strong>{`、たとえば小さいWindow画面のまま移動させたい場合でも、画面分割の領域に入るとWindowサイズが勝手に拡大されて迷惑な場合もある。`}</p>
    <p>{`領域の微調整が効くなど優れたソフトウェアであることは間違いないが、Windowsに使い慣れた人にとって違和感の多いものであるし、分割を最初に定義することによりWindowサイズ調整の柔軟性を大きく損なっていると感じる。`}</p>
    <p>{`結局私の場合は`}<strong parentName="p">{`HDMIを4本使い、FullHD４画面として使うのが一番快適`}</strong>{`であった。`}</p>
    <p>{`FullHD4枚の方法はグラフィックボードからHDMIやDisplayPortを２本、パソコン本体から同様に２本モニタに挿せば大半は認識をしてくれる。ゲームをするわけでないならば`}<a parentName="p" {...{
        "href": "https://amzn.to/2JGstZD",
        "target": "_blank",
        "rel": "nofollow noopener"
      }}>{`GeForce GT 1030`}</a>{`あたりの低価格グラフィックボードで十分である。`}</p>
    <p>{`結論として`}<strong parentName="p">{`画面の使いやすさは4Kmonitorを4画面出力してクワトロモニタとして使う > OnScreenControlなどのソフトウェアで画面分割しやすくして使う > そのまま使う`}</strong>{`であった。`}</p>
    <h2 {...{
      "id": "FullHD画面同士の整列が微妙にずれる",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#FullHD%E7%94%BB%E9%9D%A2%E5%90%8C%E5%A3%AB%E3%81%AE%E6%95%B4%E5%88%97%E3%81%8C%E5%BE%AE%E5%A6%99%E3%81%AB%E3%81%9A%E3%82%8C%E3%82%8B",
        "aria-label": "FullHD画面同士の整列が微妙にずれる permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`FullHD画面同士の整列が微妙にずれる`}</h2>
    <p>{`FullHDの４枚出力でも欠点はある。それは`}<strong parentName="p">{`画面の切れ目を他の画面に正確に合わせられないこと`}</strong>{`である。どういうことかというと、それぞれの画面が接するところで画面同士のずれが目立ってしまうということである。`}</p>
    <Image {...props} name="monitoredge" alt="4画面の位置が微妙に合わない" mdxType="Image" />
    <p>{`4K画像や映像を楽しもうとしたときは、このずれが邪魔となってしまう。HDMIのうちの１本から4K出力を行い、楽しみ終わったらもとに戻すという作業をすることもあるが、面倒である。`}</p>
    <h2 {...{
      "id": "画面サイズが大きいので机には奥行きが必要",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E7%94%BB%E9%9D%A2%E3%82%B5%E3%82%A4%E3%82%BA%E3%81%8C%E5%A4%A7%E3%81%8D%E3%81%84%E3%81%AE%E3%81%A7%E6%9C%BA%E3%81%AB%E3%81%AF%E5%A5%A5%E8%A1%8C%E3%81%8D%E3%81%8C%E5%BF%85%E8%A6%81",
        "aria-label": "画面サイズが大きいので机には奥行きが必要 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`画面サイズが大きいので机には奥行きが必要`}</h2>
    <p>{`43インチの画面全体を見渡すにはある程度、画面との距離を取る必要がある。このため机の奥行きは快適な作業のために重要となる。`}</p>
    <p>{`私の`}<strong parentName="p">{`デスクは奥行きが55cmであるが、この43インチディスプレイには浅すぎた感が否めない`}</strong>{`。43インチディスプレイ自体の奥行きも24インチ程度のモニタと比べるとあるので、全体を見渡すために必要な奥行きと合わせて`}<strong parentName="p">{`最低でも65cm程度の奥行きは確保しておきたいところ`}</strong>{`である。`}</p>
    <p>{`私の場合、`}<strong parentName="p">{`パソコンチェアのひじ掛けに肘を乗せてタイピングをしているため何とかなっている`}</strong>{`が、奥行きの浅いデスクでかつひじ掛けなしのチェアで前傾姿勢を取りつつ43インチのディスプレイに向かうのは自殺行為といえる。`}</p>
    <h2 {...{
      "id": "文字が思ったよりも小さい",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E6%96%87%E5%AD%97%E3%81%8C%E6%80%9D%E3%81%A3%E3%81%9F%E3%82%88%E3%82%8A%E3%82%82%E5%B0%8F%E3%81%95%E3%81%84",
        "aria-label": "文字が思ったよりも小さい permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`文字が思ったよりも小さい`}</h2>
    <p>{`43インチの画面を４分割すると１枚あたりは21.5インチなのでFullHDで使った時に文字サイズはそれほど問題にならないのではないか？と購入前は思っていた。`}</p>
    <p>{`しかし実際はある程度画面との距離を取る必要があるため、モニタと目との距離は通常より長くなる、という理由と、画面が曲面をしていないため画面端と目の距離が遠くなるという理由により文字を大きくする必要があった。`}</p>
    <Image {...props} name="lettersize" alt="ディスプレイの選択と整理からサイズを変更" mdxType="Image" />
    <p>{`このため私は`}<strong parentName="p">{`文字サイズを1.25倍して普段使用`}</strong>{`している。ディスプレイ設定を開くと設定する項目にたどりつける。`}</p>
    <p>{`上の画面は特に距離が離れるため、上だけ文字を大きくし、下はしないなどの設定をしている人もいる。`}</p>
    <p>{`31.5インチの4Kモニタも広く普及しているが、文字サイズを考えると画面4枚は厳しいかと思われる。`}</p>
    <h2 {...{
      "id": "正面からモニタを見れないのはなかなか辛い",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E6%AD%A3%E9%9D%A2%E3%81%8B%E3%82%89%E3%83%A2%E3%83%8B%E3%82%BF%E3%82%92%E8%A6%8B%E3%82%8C%E3%81%AA%E3%81%84%E3%81%AE%E3%81%AF%E3%81%AA%E3%81%8B%E3%81%AA%E3%81%8B%E8%BE%9B%E3%81%84",
        "aria-label": "正面からモニタを見れないのはなかなか辛い permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`正面からモニタを見れないのはなかなか辛い`}</h2>
    <p>{`デュアルモニタと比べた時に辛いと思ったことが、画面の向きをそれぞれ物理的に変える事ができないということである。`}</p>
    <p>{`デュアルモニタならば、画面を自分の方面にそれぞれ向けることでモニタとの距離を一定に保つことができ、またディスプレイを横からみるということをしなくとも良いが4Kモニタを１枚で使おうとした場合はこうはいかない。`}</p>
    <p>{`結果斜めでかつ離れた位置で画面の端を見なければならず辛いところである。`}</p>
    <h2 {...{
      "id": "モニタの上の方は目が疲れる",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%83%A2%E3%83%8B%E3%82%BF%E3%81%AE%E4%B8%8A%E3%81%AE%E6%96%B9%E3%81%AF%E7%9B%AE%E3%81%8C%E7%96%B2%E3%82%8C%E3%82%8B",
        "aria-label": "モニタの上の方は目が疲れる permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`モニタの上の方は目が疲れる`}</h2>
    <p>{`あまり予想はしていなかったのだが、画面の上を見ると目が疲れる。調べると上向き視線は目の緊張が高まることで目の筋肉を使う上乾燥もしやすいそうだがなるほどその通りだと思われる。`}</p>
    <p>{`なので４画面のうち下の２つに良く使うアプリをおいて、上２つを補助的に使っている。ただここで問題なのが、上からの画面の光自体もそれなりに鬱陶しいことである。`}<strong parentName="p">{`下の画面を見つめていても上からモニタの光が入ってきて、そのこと自体も目が疲れる原因になっている`}</strong>{`ように感じる。`}</p>
    <p>{`まあ私としては何となく不快ぐらいの感じで個人差はあるとは思うのだが、モニタの大画面の前に座り長時間作業を行うというのはあまり健康面でよろしくない感じを受ける。`}</p>
    <h2 {...{
      "id": "作業効率はデュアルモニタと4K43インチと比べてどうか？",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E4%BD%9C%E6%A5%AD%E5%8A%B9%E7%8E%87%E3%81%AF%E3%83%87%E3%83%A5%E3%82%A2%E3%83%AB%E3%83%A2%E3%83%8B%E3%82%BF%E3%81%A84K43%E3%82%A4%E3%83%B3%E3%83%81%E3%81%A8%E6%AF%94%E3%81%B9%E3%81%A6%E3%81%A9%E3%81%86%E3%81%8B%EF%BC%9F",
        "aria-label": "作業効率はデュアルモニタと4K43インチと比べてどうか？ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`作業効率はデュアルモニタと4K43インチと比べてどうか？`}</h2>
    <p>{`24インチのディスプレイを２枚並べる場合と4K43インチをHDMI4本接続の４画面で利用する場合で作業効率はどうだろうか？`}</p>
    <p>{`画面数が多いという点では4K4画面に当然軍配が上がる。たとえばWebサイトを作成する時は、Editorで１枚、動作確認用に１枚画面を使うので、調べ物をする、画像を作成するなどの用途で他にも画面を使いたい時はよくある。このような時にわざわざ画面を閉じて、他のWindowを前面に出してという作業をせずに済むため便利である。`}</p>
    <p>{`ただし前述した目が疲れるであったり自然な態勢をとることが難しいなどもあり、画面数以外の作業の快適さではデュアルモニタに軍配が上がる。長時間の作業ではこちらの方が生産性が高い場合もありそうである。`}</p>
    <p>{`よって`}<strong parentName="p">{`一概に判断することはできないし、人や作業の内容にも依存してくる`}</strong>{`ように感じる。たとえば滅多に３画面目を使うことがないならばデュアルモニタで十分であろうし、常にモニタリングが必要な画面があるならば4k43インチモニタは最適な選択肢となるだろう。`}</p>
    <h2 {...{
      "id": "まとめ",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%81%BE%E3%81%A8%E3%82%81",
        "aria-label": "まとめ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`まとめ`}</h2>
    <p>{`4K43インチのモニタは目が疲れる、端の方の文字が小さくて見にくい、奥行きのあるデスクが必要など様々な問題を抱えているものの、43インチの大画面で動画や静止画を楽しめる、電源も一つで済む、多くの画面を使う作業で効率が上がるなどメリットも大きい。`}</p>
    <p>{`個人としては３画面使うことはあっても４画面まで使うことはあまりない、かつ大画面用途では別途テレビがあるため、デュアルモニタに戻して補助モニタとして３画面目を用意しようかなど考え中である。`}</p>
    <p>{`ともあれ使い方によって有力な選択肢となることは間違いないので参考にして欲しい。`}</p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      